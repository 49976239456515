import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { HiMenuAlt4 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { shortenAddress } from "../utils/ShortAdress";
import { FaHammer} from "react-icons/fa";
import hlogo from '../assets/ethereum.png';
import { AppContext } from "../context/AppContext";


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className="w-full flex md:justify-center justify-between items-center p-4">
      
      <ul className="text-white md:flex hidden list-none flex-row justify-between items-center flex-initial">
      </ul>
    </nav>
  );
};

export default Navbar;
