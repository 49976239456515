import { ethers } from "ethers";
import React, { useState, createContext, useEffect } from 'react';
const Wallet = require('./Wallet');
const Alert = require("sweetalert2");
const BigNumber = require('big-number');


export const AppContext = createContext(); // global apk context

var raumSaleAbi = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_rnTokenAddress",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Log",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "buy",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "conversionRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rnTokenAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_conversionRate",
				"type": "uint256"
			}
		],
		"name": "setConversionRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_to",
				"type": "address"
			}
		],
		"name": "withdrawMaticTokens",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawRnTokens",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]

export const AppProvider = ({ children }) => {

    const [loading, setLoading] = useState(false);
    const [currentAccount, setCurrentAccount] = useState();
    const [networkId, setNetworkId] = useState();
    const [networkRpc, setNetworkRpc] = useState();
    const [networkProvider, setNetworkProvider] = useState();

    const ourProvider = async () => {
        const provider = await Wallet.provider();
        setNetworkProvider(provider);
    }
    // connect to wallet
    // const connectWallet = async () => {
    //     await ourProvider();
    //     const account = await Wallet.connect();
    //     console.log("le compte =>", account);
    //     setCurrentAccount(account[0]);
    // }

	const connectWallet = async () => { 
        await ourProvider(); 
        const account = await Wallet.connect(); 
  		console.log(await Wallet.verifyPolygon()) 
  		if (await Wallet.verifyPolygon()) { 
   			console.log("le compte =>", account); 
   			setCurrentAccount(account[0]); 
  		} else { 
  			Wallet.disconnect(); 
   			alert("Please change to Polygon Mainnet"); 
  		} 
    }

    const getToken = async (maticTokens) => {
        try {
            // console.log()
          	const contract = new ethers.Contract("0x5d20c42d9b517e593856a4671D185B9b598aA594", raumSaleAbi, await Wallet.signer());
		maticTokens = maticTokens * (10**18)
		maticTokens = maticTokens.toLocaleString('fullwide', {useGrouping:false});
		
		const contractCaller = await contract.buy({value: maticTokens});
		
            // // console.log (contractCaller)
            // setTransactionHash((contractCaller.hash));
            // contract.buy({value:  maticTokens.toString()}).then( a => {
            // 	console.log("1!!!!!!" + a)
            // });
        } catch (error) {
		console.log(error);
            		var err = '';
			if ( error.data ) {
				err = error.data.message;
			} else {
				err = error.message.substring(0, error.message.indexOf('[')); 
			}
			alert(err);
		}
		//mcblankline
    }

    // disconnect from wallet
    const disconnectWallet = async () => {
        await Wallet.disconnect();
        setCurrentAccount();
    }

    // verify network
    const verifyNetwork = async (networkId, networkName, networkRpc) => {
        console.log('In context, networkId is =>', networkId);
        const verify = Wallet.verifyNetwork(networkId, networkName, networkRpc);
        setNetworkId(verify)
        setNetworkRpc(networkRpc);
    }


    // every time page is loaded, connect to wallet
    // useEffect(() => {
    //     if (Wallet.web3Modal.cachedProvider) {
    //         connectWallet();
    //     }
    // }, []);

    // every time provider is changed, follow the change
    useEffect(() => {
        if (networkProvider?.on) {
            const handleAccountsChanged = (accounts) => { // accounts changed
                console.log("accountsChanged", accounts);
                if (accounts) setCurrentAccount(accounts[0]);
                Wallet.web3Modal.clearCachedProvider();
            };

            const handleChainChanged = async (_hexChainId) => { // chain changed event
                console.log("chainChanged", _hexChainId);
                console.log('account when chain change', currentAccount);
                Alert.fire({
                    icon: "info",
                    title: "Network change",
                    confirmButtonText: "Ok",
                    text: "Make sure you are on the right network",
                    width: 'auto'
                })
            };

            const handleDisconnect = () => { // disconnect event
                disconnectWallet();
            }

            networkProvider.on("accountsChanged", handleAccountsChanged);
            networkProvider.on("chainChanged", handleChainChanged);
            networkProvider.on("disconnect", handleDisconnect);

            return () => {
                if (networkProvider.removeListener) {
                    networkProvider.removeListener("accountsChanged", handleAccountsChanged);
                    networkProvider.removeListener("chainChanged", handleChainChanged);
                    networkProvider.removeListener("disconnect", handleDisconnect);
                }
            };
        }
    }, [networkProvider]);
    return (
        <AppContext.Provider value={{ connectWallet, currentAccount, verifyNetwork, loading, getToken }}>
            {children}
        </AppContext.Provider>
    );
};
