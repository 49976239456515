import React,{useContext} from 'react';
import { FaConnectdevelop } from 'react-icons/fa';
import { AppContext } from '../context/AppContext';
import { shortenAddress } from "../utils/ShortAdress";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Swap from './swap';
import { color } from '@mui/system';

var a = 0; 


const Welcome = () => {
    const { currentAccount, connectWallet } = useContext(AppContext);

    return (
        <div className="welcome flex max-w-[1500px] m-auto justify-center items-center p-[100px] ">
            <div className="welcome-div-text md:flex-[0.5] flex justify-center px-20 flex-wrap items-center self-center">
                <div sx={{fontFamily:"open sans"}}className=" w-full text-white text-center text-4xl py-3 font-bold">Phase Two</div>
                
                <div className="welcome-button flex items-center cursor-pointer" >

                            {
                                currentAccount
                                ? <Swap/>
                                     : <button sx = {{mb:6}}
                                     type='button'
                                     onClick={connectWallet}
                                     className='flex flex-row justify-center items-center my-5 bg-[#2952e3] p-3 rounded-lg cursor-pointer hover:bg-[#2546bd]'>
                                     <p className="text-white text-base font-semibold">
                                         {!currentAccount
                                             ? "Connect Wallet"
                                             
                                             : "Connected with" +  shortenAddress(currentAccount)}
                                     </p>
                                 
                                 </button>
                                 
                            }
                </div>

                <div sx={{ mt: 4 ,color:"white"}} className="items-center">
                    <br></br>
                    <h1>
                        <p className=' w-full text-white text-center text-3xl py-3 font-bold' >Early Supporters Token Sale</p>
                    </h1>
                    <br></br>
                    <div >
                    <p style={{color:"white",fontFamily:"open sans",textAlign:'left'}}>
                    (i) Before making the transaction for token sale, kindly be sure that the network selected in Metamask or any similar wallet is “Polygon Mainnet”. We won’t be able to help if the transaction made on any other chain other than Polygon Mainnet. 
                    </p>
                    </div>
                    <br ></br>
                    <br></br>
                    <div>
                        <p style={{color:"white",fontFamily:"open sans",textAlign:'left'}}>(ii) Minimum Buy limit is 100 MATIC whereas Maximum Buy limit is 10000 MATIC for one wallet/one transaction.</p>
                    </div>
                    
                    <br></br>
                    <br></br>
                    <div>
                        <p style={{color:"white",fontFamily:"open sans",textAlign:'left'}}>(iii) Currently, we are conducting Round 1, i.e. Early Supporters Campaign. The price of the tokens which are currently sold is based upon & subjected to the tokenomics available on our website.</p>
                    </div>
                    
                    <br></br>
                    <br></br>
                    <div>
                        <p style={{color:"white",fontFamily:"open sans",textAlign:'left'}}>
                        (iv) By participating in this campaign, you accredit that you have read the Terms & Conditions along with that you are participating in capacity of an individual (and beneficial owner) and not as an agent or representative of a third party corporate entity.
                        </p>
                    </div>
                    
                    <br></br>
                    <br></br>
                    <div>
                        <p style={{color:"white",fontFamily:"open sans",textAlign:'left'}}>
                        You can reach to our support team at "support@raum.network" or ping us at any social media channel with your grievances and we will be happy to sort it out.
                        </p>
                    </div>
                   
                </div>
            </div>
        </div>
    );
};


export default Welcome;
