import React, {useContext, useState} from "react";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { ethers } from 'ethers';

import { AppContext } from '../context/AppContext';
import { color } from "@mui/system";


function Swap() {

  const { getToken } = useContext(AppContext);


  const [rnAmount, setRn] = useState(0);
//   const [connectWallet, setConnectWallet] = useState("Connect Metamask");
//   const [isConnect, setIsConnect] = useState(0);
//   const [accounts, setAccounts] = useState();
//   const [signer, setSigner] = useState();
  const [transactionHash, setTransactionHash] = useState(0);


//   const handleConnectWallet = async() =>{
//     if (!window.ethereum) {
//       alert("Metamask is not installed!!");
//     } else {
//       if (connectWallet == "Disconnect Metamask"){
//         window.userWalletAddress = null;
//         setConnectWallet(("Connect Metamask"));
//       } else {
//         let provider = new ethers.providers.Web3Provider(window.ethereum);
//         setSigner(( provider.getSigner() ));
//         await window.ethereum.enable();
//         let acc = await window.ethereum.request({ method: 'eth_requestAccounts' });
//         setAccounts((acc));
//         setConnectWallet(("Disconnect Metamask"))
//       }
//     }
//   }

  const handleSubmit = async(event) => {
		
		var maticTokens = (event.target.matic.value);
		if(maticTokens<50)
		{
			alert("Minimum required is 50 MATIC");
		}
		else if (maticTokens>10000){
			alert("Maximum limit is 10000 MATIC");
		}
		else{
		event.preventDefault();
		getToken(maticTokens);
		console.log(maticTokens)
		}

	}
	// var maticTokens = (event.target.matic.value);
    // event.preventDefault();
    // getToken(maticTokens)

  const onInput = async(event) => {
    event.preventDefault();
	// Issue with below approach is that the user need to sign the wallet before fetching the price
	// const contract = new ethers.Contract("0xa6cFdEBC1B0EA8392395B3b99f72496a0c38C6BF", raumSaleAbi);
	// var price = await contract.getPrice();
	var price = 0.069;
	var rnTokens = (parseFloat(event.target.value) / price).toFixed(2)
	setRn((rnTokens));
  }



  

  return (
	
				<div className="App-Header" style={{ height: '340px'}} >
					
					<Container component="main" maxWidth="xs">
						<CssBaseline />
						<Box
						sx={{
							marginTop: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
						>

						<Box component="form" style={{ backgroundColor: '#gradient-bg-welcome'}} noValidate  onSubmit={handleSubmit} sx={{ mt: 3 }}>
							<Grid container spacing={2}>
							<Grid item xs={12 } sx={{ input: { color: 'white' } }}>
								<TextField 
								type={"number"}
								color="secondary"
								required
								fullWidth
								
								id="matic"
								style={{ WebkitTextFillColor:"white",textDecoration:"black"}}
								label="Amount Of MATIC"
								name="matic"
								onInput={onInput}
								/>
							</Grid>
							<Grid item xs={12} color="white">
								<TextField
								style={{ WebkitTextFillColor:"white",textDecoration:"white"}}
								required
								fullWidth
								name="rn"
								id="rn"
								label="Amount Of RN"
								value={rnAmount} 
								// disabled={true}
								/>
							</Grid>
							</Grid>
							
							<div className="welcome flex max-w-[1500px] m-auto justify-center items-center ">
								<div className="welcome-button flex items-center cursor-pointer" >

								
									
									<button sx = {{}}
										type='submit'
										className='flex flex-row justify-center items-center my-5 bg-[#2952e3] p-3 rounded-lg cursor-pointer hover:bg-[#2546bd]'>
										<p className="text-white text-base font-semibold ">
											Get Tokens
										</p>
									
									</button>
									
								
								</div>
							</div>

						</Box>
						{ transactionHash != 0  ?
								<h6 style={{fontFamily: "open sans sans-serif", fontSize:"16px"}}>
									Please check you transaction <a href={`https://polygonscan.com/tx/${transactionHash}`} target="_blank">here</a>
								</h6>
						: <div style={{minHeight:49}}></div> }
						</Box>
					</Container>
	</div>
  );
}

export default Swap;
