import React from "react";
import { Welcome } from "../components/index";

const Home = () => {
  return (
    <div>
      <Welcome/>
    </div>
  )
};

export default Home;
